<template>
  <TransitionRoot as="template" :show="props.open">
    <Dialog as="div" class="relative z-50" @close="emit('close')">
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden bg-black/15">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full md:pl-16"
          >
            <TransitionChild
              as="template"
              :enter="
                props.disableEnterTransition
                  ? 'transition-opacity ease-linear duration-300'
                  : 'transform transition ease-in-out duration-500 sm:duration-700'
              "
              :enter-from="
                props.disableEnterTransition ? 'opacity-0' : 'translate-x-full'
              "
              :enter-to="
                props.disableEnterTransition ? 'opacity-100' : 'translate-x-0'
              "
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel
                :class="[
                  props.wide ? 'max-w-[768px]' : 'max-w-md',
                  'pointer-events-auto w-screen',
                ]"
              >
                <div
                  class="isolate flex h-[100dvh] flex-col bg-white shadow-xl"
                >
                  <div
                    :style="{ 'max-height': visualHeight }"
                    class="flex h-full flex-col"
                  >
                    <div
                      v-if="!props.hideHeader"
                      class="z-20 flex flex-col shadow-[0_4px_7px_0_rgba(0,0,0,0.1)]"
                    >
                      <div
                        class="flex w-full flex-row items-center justify-between px-4 py-2.5 md:px-6 md:py-[15px]"
                      >
                        <button
                          class="group flex h-11 w-11 flex-none items-center justify-center border-2 border-neutral-200 transition-all hover:border-black"
                          @click="emit('close')"
                        >
                          <component
                            :is="closeIcon"
                            class="transform-gpu stroke-2 duration-700"
                            :class="
                              props.closeIcon === IconClose &&
                              'group-hover:rotate-90'
                            "
                          />
                        </button>

                        <div class="headline-xl-2 w-full text-center">
                          <slot name="heading" />
                        </div>

                        <slot name="headingButtons" />
                      </div>

                      <div id="slideover-header-panel" class="md:hidden" />
                    </div>

                    <div
                      :class="[
                        !props.paddingXZero && 'px-6',
                        'z-10 h-full flex-1 grow overflow-y-auto',
                      ]"
                    >
                      <slot />
                    </div>

                    <div>
                      <slot name="button" />
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import IconClose from "@ui/components/icon/Cancel.vue";

const props = withDefaults(
  defineProps<{
    open: boolean;
    wide?: boolean;
    paddingXZero?: boolean;
    disableEnterTransition?: boolean;
    hideHeader?: boolean;
    closeIcon?: Component;
  }>(),
  {
    wide: false,
    paddingXZero: false,
    disableEnterTransition: false,
    closeIcon: IconClose,
  },
);
const emit = defineEmits<{ close: [] }>();

const visualHeight = ref(null);

const resizeHandler = () => {
  visualHeight.value = `${window.visualViewport?.height}px`;
};

onMounted(() => {
  window.visualViewport?.addEventListener("resize", resizeHandler);
});

onUnmounted(() => {
  window.visualViewport?.removeEventListener("resize", resizeHandler);
});

const handleFocus = (event) => {
  if (
    event.target.matches(
      'input[type="text"], input[type="password"], input[type="email"], input[type="number"], input[type="date"], input[type="search"], input[type="tel"], input[type="url"], textarea, [contenteditable="true"]',
    ) &&
    window.visualViewport?.width < 768
  ) {
    setTimeout(() => {
      window.scrollTo(0, -1);

      setTimeout(() => {
        event.target.scrollIntoView({
          block: "end",
          behavior: "smooth",
        });
      }, 400);
    }, 200);
  }
};

onMounted(() => {
  window.addEventListener("focusin", handleFocus, true);
});

onUnmounted(() => {
  window.removeEventListener("focusin", handleFocus, true);
});
</script>
